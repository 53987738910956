@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

.app-body {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 40px 20px; */
}

.app-header::before {
  content: " ";
  position: absolute;
  width: 2px;
  background-color: #1706ae80;
  left: -12px;
  top: 16px;
  bottom: 12px;
}

.app-header {
  font-size: 50px;
  position: relative;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.app-text {
  font-size: 16px;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  max-width: 640px;
  margin-left: 25px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.app-text a {
  text-decoration: none;
  font-size: 16px;
  color: #1706ae80;
}

.app-text a:hover {
  text-decoration: underline;
}

.column-1 {
  font-size: 14px;
  float: left;
  width: 70%;
}
.column-2 {
  float: left;
  width: 30%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

hr {
  border-top: 1px dashed #1706ae80;;
}